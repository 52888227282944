import React from "react";
// import img from "../../assets/img/pp.jpg";

const Presentation = () => {
  const img =  "https://images.pexels.com/photos/4353617/pexels-photo-4353617.jpeg?auto=compress&cs=tinysrgb&w=600";
  return (
    <div className="Presentation">
      <div className="boxes">
        <div className="leftBox">
            <h2>Bonpayy , qu'est ce que c'est ?</h2>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem nam
            quia nisi esse sunt culpa quam veniam distinctio? Nobis similique
            odio harum fugit provident consequuntur, veritatis repellendus
            illum? Aspernatur, iure.
          </p>
          <div className="blocBtn">
            <button className="btn">S'inscrire</button>
            <button className="btn">Devenir vendeur</button>
          </div>
        </div>
        <div className="rightBox">
          <img src={img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Presentation;
