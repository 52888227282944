import React from 'react';
import TitreTable from '../../../../components/titres/TitreTable';
import Infoperso from '../../../../components/parametres/infoperso';
import Editmdp from '../../../../components/parametres/editmdp';
import DeleteCompte from '../../../../components/parametres/deleteCompte';
import DeleteBoutique from '../../../../components/parametres/deleteBoutique';
import Infoboutique from '../../../../components/parametres/infoboutique';

const Parametres = () => {
   
    return (
      <>
        <TitreTable titre="Mes paramètres" />

        <div className="bentoParametre">
          <Infoperso />
          <Editmdp />
          <Infoboutique />
          <DeleteBoutique />
          <DeleteCompte />
        </div>
      </>
    );
}

export default Parametres;
