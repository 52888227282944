import React from 'react';

const Logout = () => {
    return (
        <h1>
            Revenez nous très vite!!!!!
        </h1>
    );
}

export default Logout;
