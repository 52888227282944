import React from "react";
import "./dashboard.css";
import TicketDashboard from "../../../components/tickets/ticketDashboard";
import BestOf from "../../../components/dashboard/bestOf";
import ArticleRecent from "../../../components/dashboard/articleRecent";
import { formatNombre, tarif } from "../../../helper/fonctions";

const Dashboard = () => {
  return (
    <>
      <div id="mytickets">
        <div id="tickets-stat">
          <TicketDashboard etat="accepted" qte={formatNombre(10)} />
          <TicketDashboard etat="waiting" qte={formatNombre(3)} />
          <TicketDashboard etat="rejected" qte={formatNombre(1)} />
          <TicketDashboard qte={tarif(50000)} />
        </div>
      </div>
      <div className="bento">
        <BestOf />
        <ArticleRecent />
        <BestOf />
      </div>
    </>
  );
};

export default Dashboard;
