import React, { useRef } from "react";
import {  tarif } from "../../helper/fonctions";
import EmptyCart from "../empty/emptyCart";

const Cart = ({ cart, remove }) => {
  const viewCart = useRef();
  function handleClic(e) {
    viewCart.current.classList.toggle("cart-mobile");
  }

  function CartRow(p, index) {
    return (
      <li className="cart-item" key={index}>
        <span className="quantity"> {p.qte} - </span>
        <div className="item-info">
          <p>
            <span>{p.designation}</span>
            <span className="item-price">{tarif(p.prix)} </span>
          </p>
          <i className="fa-solid fa-trash" onClick={() => remove(p.id)}></i>
        </div>
      </li>
    );
  }

  function Total() {
    let total = 0;
    cart.forEach((c) => {
      let article = c.prix * c.qte;
      total = total + article;
    });

    return tarif(total);
  }

  function cartContent() {
    if (cart.length < 1) {
      return (
        <ul id="list-cart">
          <EmptyCart />
        </ul>
      );
    }

    return (
      <ul id="list-cart">
        {cart.map((produit, index) => {
          return CartRow(produit, index);
        })}
      </ul>
    );
  }
  return (
    <>
      <div id="cart" className="az" ref={viewCart}>
        <div id="cart-header">
          <h2>Mon panier</h2>
          <p>
            <span id="cart-price">{Total()}</span> 
          </p>
        </div>
        <div className="body-cart">
          <div id="cart-footer">
            {cartContent()}
            <button className="btn">Submit</button>
          </div>
        </div>
      </div>
      <i
        id="btn-cart"
        className="fa-solid fa-basket-shopping"
        onClick={handleClic}
      ></i>
    </>
  );
};

export default Cart;
