import React from "react";
import LineCaisse from "../../../components/caisse/lineCaisse";
import TitreTable from "../../../components/titres/TitreTable";
import { tarif } from "../../../helper/fonctions";

const Caisse = () => {
  return (
    <div id="profile">
      <div id="tickets-stat">
        <div className="ticket-stat stat-bonpay">
          <div className="ticket-stat-header">
            <div className="ticket-stat-info">
              <h3>{tarif(10000)}</h3>
              <p>used in Bonpay </p>
            </div>
            <div className="ticket-stat-icon">
              <i className="fa-solid fa-hand-holding-dollar"></i>
            </div>
          </div>
        </div>
        <div className="ticket-stat stat-orange" id="">
          <div className="ticket-stat-header">
            <div className="ticket-stat-info">
              <h3>{tarif(4000)} </h3>
              <p>non-refunded</p>
            </div>
            <div className="ticket-stat-icon">
              <i className="fa-regular fa-hourglass-half"></i>
            </div>
          </div>
        </div>
        <div className="ticket-stat stat-green" id="">
          <div className="ticket-stat-header">
            <div className="ticket-stat-info">
              <h3>{tarif(6000)} </h3>
              <p>refunded</p>
            </div>
            <div className="ticket-stat-icon">
              <i className="fa-solid fa-check"></i>
            </div>
          </div>
        </div>
      </div>
      <div className="profile-table">
        <TitreTable titre="Ma caisse" />
        <table id="myTable" className="display row-clickable">
          <thead>
            <tr>
              <th>#</th>
              <th>periode</th>
              <th>montant</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <LineCaisse />
            <LineCaisse />
            <LineCaisse />
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Caisse;
