import React from 'react';
import Fieldset from "../form/fieldset";

const DeleteBoutique = () => {

    
function handleSubmit(e) {
  e.preventDefault();
  alert("boutique effacée");
}

    return (
      <form onSubmit={handleSubmit} className="form-setting">
        <Fieldset legend="Supprimer ma boutique" color="danger">
          <p className="form-advertising">
            Vous êtes sur le point de supprimer votre boutique . Ceci est une
            action dangereuse.
          </p>
          <div className="form-group">
            <button className="btn">Supprimer</button>
          </div>
        </Fieldset>
      </form>
    );
}

export default DeleteBoutique;
