import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div className="blocks">
        <div className="block">
          <h3>Titre</h3>
          <ul>
            <li>
              <Link to={"/terms"}>Conditions d'utilisations</Link>
            </li>
            <li>
              <Link to={"/terms"}>Politique de confidentialité</Link>
            </li>
            <li>
              <Link to={"/terms"}>Conditions d'utilisations</Link>
            </li>
          </ul>
        </div>
        <div className="block">
          <h3>Titre</h3>
          <ul>
            <li>
              <Link to={"/terms"}>Assistance au vendeurs</Link>
            </li>
            <li>
              <Link to={"/terms"}>A Propos</Link>
            </li>
            <li>
              <Link to={"/terms"}>Partenaires</Link>
            </li>
          </ul>
        </div>
        <div className="block">
          <h3>Titre</h3>
          <ul>
            <li>
              <Link to={"/terms"}>Assistance au vendeurs</Link>
            </li>
            <li>
              <Link to={"/terms"}>A Propos</Link>
            </li>
            <li>
              <Link to={"/terms"}>Partenaires</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="social">
        <ul>
          <li>
            <a href="http://facebook.com">
              <i className="fa-brands fa-facebook"></i>
            </a>
          </li>
          <li>
            <a href="http://facebook.com">
              <i className="fa-brands fa-instagram"></i>
            </a>
          </li>
          <li>
            <a href="http://facebook.com">
              <i className="fa-brands fa-tiktok"></i>
            </a>
          </li>
          <li>
            <a href="http://facebook.com">
              <i className="fa-brands fa-x-twitter"></i>
            </a>
          </li>
          <li>
            <a href="http://facebook.com">
              <i className="fa-brands fa-linkedin"></i>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Footer;
