import React from 'react';
import Fieldset from "../form/fieldset";

const Editmdp = () => {


function handleSubmit(e){
e.preventDefault()
alert('mot de passe modifer')


}





    return (
      <form className="form-setting" onSubmit={handleSubmit}>
        <Fieldset legend="Changer mon mot de passe" color="warning">
          <div className="form-group">
            <i className="fa-solid fa-lock"></i>
            <input
              type="password"
              className="auth-input"
              placeholder="Entrez votre mot de passe actuel"
            />
          </div>

          <div className="form-group">
            <i className="fa-solid fa-lock"></i>
            <input
              type="password"
              placeholder="Entrez le nouveau mot de passe"
            />
          </div>
          <div className="form-group">
            <button className="btn">Mettre à jour</button>
          </div>
        </Fieldset>
      </form>
    );
}

export default Editmdp;
