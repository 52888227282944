import React from 'react'; 
import { Link, useNavigate } from "react-router-dom";

const Signup = () => {



  const navigate = useNavigate();

  function handleLogin(e) {
    e.preventDefault();
    navigate("/v/");
  }

  



    return (
      <div id="block">
        <div id="data">
          <div id="message">
            <h1>Deja inscrit ???</h1>
            <p>Vous avez déja un compte? Connectez vous . </p>
            <Link to="/login" className="btn">
              Se connecter
            </Link>
          </div>
          <div id="login">
            <div id="head">
              <h3>S'inscrire </h3>
            </div>
            <form onSubmit={handleLogin}>
              <div className="form-group">
                <i className="fa-solid fa-envelope"></i>
                <input
                  type="email"
                  placeholder="Email"
                  className="auth-input"
                />
              </div>
              <div className="form-group">
                <i className="fa-solid fa-lock"></i>
                <input
                  type="password"
                  placeholder="Password"
                  className="auth-input"
                />
              </div>
              <div className="form-group">
                <i className="fa-solid fa-lock"></i>
                <input
                  type="password"
                  placeholder="Confirm assword"
                  className="auth-input"
                />
              </div>
              <div className="form-group">
                <button type="submit" className="btn">
                  sign-up
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
}

export default Signup;
