import React from 'react';

const TitreTable = ({titre}) => {

 

    return (
      <div className="header-title">
        <h2>{titre}</h2>
      </div>
    );
}

export default TitreTable;
