import React, { useRef } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo/logo.png";
import Avatar from "../avatar/avatar";
import Logout from "../boutons/logout/logout";
import { AuthService } from "../../services/auth.service";

const Uheader = () => {

  const nom = AuthService.getNom()
  const MenuUser = useRef();
  function handleClicMenu(e) {
    MenuUser.current.classList.toggle("active");
    window.scrollTo(0, 0);
  }
  

  function handleClicMenuBtn(e) {
    MenuUser.current.classList.toggle("active");
  }
 
  return (
    <>
      <header className="pheader">
        <Link to={"/"} className="logo">
          {" "}
          <img src={logo} alt="" />
        </Link>
      </header>
      <aside className="aside " ref={MenuUser}>
        <div id="aside-header">
          <Avatar 
            src="https://images.pexels.com/photos/428333/pexels-photo-428333.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" 
            linkEdit="/u/parametres"
          />
        </div>
        <ul id="aside-body">
          <li onClick={handleClicMenu}>
            <Link to="/u/dashboard">
              <i className="fa-solid fa-dashboard"> </i>
              <span>Tableau de bord</span>
            </Link>
          </li>
          {/* <li onClick={handleClicMenu} >
          <Link to="../mystore/createStore.php">
            <i className="fa-solid fa-store"> </i>
            <span>My store</span>
          </Link>
        </li> */}
          <li onClick={handleClicMenu}>
            <Link to="mesvendeurs">
              <i className="fa-solid fa-users"></i>
              <span>Mes vendeurs</span>
            </Link>
          </li>
          <li onClick={handleClicMenu}>
            <Link to="caisse">
              <i className="fa-solid fa-cash-register"></i>
              <span>caisse</span>
            </Link>
          </li>
          <li onClick={handleClicMenu}>
            <Link to="demandes">
              <i className="fa-solid fa-file"></i>
              <span>
                Mes demandes 
              </span><b>2</b>
            </Link>
          </li>
          <li onClick={handleClicMenu}>
            <Link to="mestickets">
              <i className="fa-solid fa-ticket"></i>
              <span>Mes tickets</span> <b>4</b>
            </Link>
          </li>
          <li onClick={handleClicMenu}>
            <Link to="profil">
              <i className="fa-solid fa-user"></i>
              <span>{nom}</span>
            </Link>
          </li>
          <li onClick={handleClicMenu}>
            <Link to="parametres">
              <i className="fa-solid fa-gear"></i>
              <span>parametres</span>
            </Link>
          </li>
        </ul>
        <Logout/>
      </aside>

      <div id="btn-aside" onClick={handleClicMenuBtn}>
        <i className="fa-solid fa-bars-staggered" id="icon_aside"></i>
      </div>
    </>
  );
};

export default Uheader;
