import React from "react";
import { Link } from "react-router-dom"; 

const TicketDashboard = ({etat ,qte}) => {
  var info = {
    color: " stat-bonpay",
    libelle: "dépensé",
    icon: "fa-solid fa-ticket",
  };
  if (etat === "accepted") {
    info = {
      color: " stat-green",
      libelle: "Tickets acceptés",
      icon: "fa-solid fa-ticket",
    };
  }
  if (etat === "waiting") {
    info = {
      color: " stat-orange",
      libelle: "Tickets en attente",
      icon: "fa-solid fa-hourglass-half",
    };
  }
  if (etat === "rejected") {
    info = {
      color: " stat-red",
      libelle: "Tickets refusés",
      icon: "fa-solid  fa-xmark",
    };
  } 
  
  

  return (
    <div className={"ticket-stat " + info.color + ""}>
      <div className="ticket-stat-header">
        <div className="ticket-stat-info">
          <h3>{  qte }</h3>
          <p>{info.libelle}</p>
        </div>
        <div className="ticket-stat-icon">
          <i className={info.icon}></i>
        </div>
      </div>
      <div className="ticket-stat-footer">
        <Link to={"/u/mestickets/" + etat + ""}>
          voir plus <i className="fa fa-arrow-circle-right"></i>
        </Link>
      </div>
    </div>
  );
};

export default TicketDashboard;
