import React from 'react';
import TitreTable from '../../../components/titres/TitreTable';

function DemandeRow({request}){
    let requestClass = request.status === 'in-waiting' ? 'status-warning' : 'status-danger';
    let requestStatus = request.status === 'in-waiting' ? 'in waiting' : 'Denied';

    return(
        <tr>
            <td>{request.id}</td>
            <td>{request.vendeur}</td>
            <td>{request.date}</td>
            <td><span className={requestClass}>{requestStatus}</span></td>
            <td><i className="fa-solid fa-trash"></i></td>
        </tr>
    );
}

const Demandes = () => {
    const demandes = [
        { id:1, vendeur:'amilou', date:'2022-12-11' , status:'in-waiting'},
        { id:2, vendeur:'Jason', date:'2022-12-11' , status:'in-waiting'},
        { id:3, vendeur:'gab', date:'2022-12-11' , status:'denied'},
        { id:4, vendeur:'Slayer', date:'2022-12-11' , status:'denied'}
    ]
    return (
        <div id="profile">
            <TitreTable titre="Mes demandes" />
            <table id="myTable" className="display">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Seller</th>
                        <th>date</th>
                        <th>Satus</th>
                        <th>Cancel</th>
                    </tr>
                </thead>
                <tbody>
                    {demandes.map((demande) => (
                        <DemandeRow request={demande}/>
                    )) }
                </tbody>
            </table>
          </div>
    );
}

export default Demandes;
