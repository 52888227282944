import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PublicRoute from "./pages/public/PublicRoute";
import "./App.css";
import UserRoute from "./pages/users/userRoute";
import Error404 from "./pages/public/Error404";
import VendeurRoute from "./pages/users/VendeurRoute";
import AuthGuard from "./helper/AuthGuard";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<PublicRoute />} />
        <Route
          path="/u/*"
          element={
            <AuthGuard role="1">
              <UserRoute />
            </AuthGuard>
          }
        />
        <Route
          path="/v/*"
          element={
            <AuthGuard role="2">
              <VendeurRoute />
            </AuthGuard>
          }
        />
        <Route path="/*" element={<Error404 />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
