import Axios from "./caller.service";

let all = () => {
  return Axios.get("vendeurs");
};
let one = (id) => {
  return Axios.get("vendeurs/" + id);
};
let allbyParticulier = (id) => {
  return Axios.get("vendeurs/" + id);
};

let create = (data) => {
  return Axios.post("vendeur/create", data);
};

let update = (data) => {
  return Axios.put("vendeur/update", data);
};

export const vendeurService = {
  all,
  create,
  one,
  update,
  allbyParticulier,
};