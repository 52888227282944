import React from 'react';
import './empty.css'

const Empty = () => {
    return (
        <h1 className='Empty-title'>
            aucune donnée trouvée
        </h1>
    );
}

export default Empty;
