import React from "react";
import { Route, Routes } from "react-router-dom";
import Playout from "../../components/layouts/playout";
import Login from "./login"; 
import Signup from "./signup";
import Logout from "./logout/logout";
import Error404 from "./Error404";
import NotAuthorized from "./notAuthorized/notAuthorized";
import Home from "./home/home";

const PublicRoute = () => {
  return (
    <Routes>
      <Route element={<Playout />}>
        <Route index element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/notfound" element={<Error404 />} />
        <Route path="/notauthorized" element={<NotAuthorized />} />
      </Route>
    </Routes>
  );
};

export default PublicRoute;
