export function formatNombre(nbr) {

		var nombre = ''+nbr;
		var retour = '';
		var count=0;
		for(var i=nombre.length-1 ; i>=0 ; i--)
		{
			if(count!==0 && count % 3 === 0)
				retour = nombre[i]+' '+retour ;
			else
				retour = nombre[i]+retour ;
			count++;
		} 
		return retour;
} 


export function tarif(nbr) {
	
  var res = formatNombre(nbr) + " Xaf";  
 
  return res;
} 
