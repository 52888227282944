import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
import NosChiffres from '../../../components/home/nosChiffres';
import './home.css'
import { vendeurService } from '../../../services/Vendeur.Service';
import { userService } from '../../../services/User.service';
import Presentation from '../../../components/home/presentation';
import Pricing from '../../../components/home/pricing';

const Home = () => {

    
  const [vendeurs, setVendeurs] = useState([]);
  const [users, setUsers] = useState([]);
  /// HOOK ///
  useEffect(() => {
    async function getVendeurs() {
      const data = await vendeurService.all().then((response) => {
        if (response.data.message === "Pas de données") {
          return response.data;
        } else {
          return response.data;
        }
      });
      setVendeurs(data.data);
    }

    getVendeurs();
    async function getUsers() {
      const data = await userService.all().then((response) => {
        if (response.data.message === "Pas de données") {
          return response.data;
        } else {
          return response.data;
        }
      });
      setUsers(data.data);
    }

    getUsers();
  }, []); 
  return (
    <>
      <Presentation />
      <NosChiffres commerce={vendeurs.length} user={users.length} />
      <Pricing />
    </>
  );
}

export default Home;
