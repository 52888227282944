import React from 'react';
import Footer from './footer';

const Pfooter = () => {
    return (
        <footer>
            <Footer/>
        </footer>
    );
}

export default Pfooter;
