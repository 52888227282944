import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo/logo.png'

const Pheader = () => {
    return (
      <header className="pheader">
        <Link to={"/"} className="logo">
          {" "}
          <img src={logo} alt="" />
        </Link>
        <nav>
          <ul>
            <li>
              <Link to="/" className="nav-link">
                <i className="fa-solid fa-home"></i>
                <span>Home</span>
              </Link>
            </li>
            <li>
              <Link to="/login" className="nav-link">
                <i className="fa-solid fa-home"></i>
                <span>Connexion</span>
              </Link>
            </li>  
          </ul>
        </nav>
        <i id="btn-mobile" className="fa-solid fa-bars "></i>
      </header>
    );
}

export default Pheader;
