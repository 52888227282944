import React from 'react';
import './ticket.css'
import { Link } from 'react-router-dom';
import LineTicket from '../../../components/tickets/lineTicket';

const Ticket = () => {
    return (
      <div id="mytickets">
        <div id="tickets-container">
          <div className="back">
            <Link to="/u/mestickets">
              <i className="fa-solid fa-arrow-left"></i>back
            </Link>
          </div>
          <div className="header-title">
            <h2>Ticket #1245</h2>
            <h2>
              Statut : <span className="status-warning">En attente</span>
            </h2>
            <h2>
              Total amount : <span className="ticket-amount">45 000</span> XAF
            </h2>
          </div>
          <table id="myTable" className="display">
            <thead>
              <tr>
                <th>#</th>
                <th>Produit</th>
                <th>quantité</th>
                <th>prix</th>
                <th>total</th>
              </tr>
            </thead>
            <tbody>
              <LineTicket />
              <LineTicket />
              <LineTicket />
              <LineTicket />
              <LineTicket />
              <LineTicket />
            </tbody>
          </table>
        </div>
      </div>
    );
}

export default Ticket;
