import React from "react";
import { formatNombre } from "../../helper/fonctions";

const LineTicket = () => {
  return (
    <tr>
      <td>1</td>
      <td>Nescafé</td>
      <td>02</td>
      <td>{formatNombre(1400120)} </td>
      <td>{formatNombre(80000000)} </td>
    </tr>
  );
};

export default LineTicket;
