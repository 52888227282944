import React from 'react';
import { Link } from 'react-router-dom';
import {  tarif } from '../../helper/fonctions';

const LineTickets = () => {
    return (
      <tr>
        <td>1</td>
        <td>amilou</td>
        <td>01/01/2023</td>
        <td>{tarif(1000120)} </td>
        <td>
          <Link to="/u/ticket/2">
            <i className="fa-solid fa-eye"></i>
          </Link>
        </td>
      </tr>
    );
}

export default LineTickets;
