import React from 'react';
import './loader.css'

const Loader = () => {
    return (
        <div className='Loading'>
            chargement en cours
        </div>
    );
}

export default Loader;
