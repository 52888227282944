import React from 'react';
import { useNavigate } from "react-router-dom";
import { AuthService } from '../../../services/auth.service';

const Logout = () => {

  const navigate = useNavigate();

function logout(){
    AuthService.logout()
 navigate("/logout"); 
}

    return (
      <div id="aside-footer" onClick={logout}>
        <li> 
            <i className="fa-solid fa-power-off log-out"></i>
            <span>Se déconnecter</span>
          
        </li>
      </div>
    );
}

export default Logout;
