import React from "react";
import "./bestOf.css";
const BestOf = () => {
function box() {
  return (
    <div className="box">
      <span>#1</span>
      <p className="title"> 
        Boite de lait <br />
        2fois
      </p>
    </div>
  );
}


  return (
    <>
      <div className="bestof">
        <h3>Meilleurs articles</h3>
        <div className="boxes">
          {box()}
          {box()}

          {box()} 
        </div>
      </div>
    </>
  );
};

export default BestOf;
