import React from 'react';

const NotAuthorized = () => {
    return (
        <>
           Authorisation requise 
        </>
    );
}

export default NotAuthorized;
