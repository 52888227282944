import React from 'react';

export default function Fieldset({legend , color , children}){
    let borderColor;
    switch(color){
        case 'danger':
            borderColor = 'fieldsetDangerColor'
            break;
        case 'success':
            borderColor = 'fieldsetSuccessColor'
            break;
        case 'warning':
            borderColor = 'fieldsetWarningColor'
            break;
        default:
            borderColor = 'fieldsetMainColor'
            break;
    }

    return (
        <fieldset className={borderColor}>
            <legend>{legend}</legend>
            {children}
        </fieldset>
    );
}

;
