import React from 'react';
import TitreTable from '../../../components/titres/TitreTable';
import './parametres.css'; 
import Infoperso from '../../../components/parametres/infoperso';
import Editmdp from '../../../components/parametres/editmdp';
import DeleteCompte from '../../../components/parametres/deleteCompte';


const Parametres = () => {
    return (
      <>
        <TitreTable titre="Mes paramètres" />

        <div className="bentoParametre">
          <Infoperso />
          <Editmdp />
          <DeleteCompte /> 
 
        </div>
      </>
    );
}

export default Parametres;
