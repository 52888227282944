import React from "react";
import Uheader from "../headers/uheader";
import { Outlet, useLocation } from "react-router-dom";
import Dashboard from "../../pages/users/particulier/dashboard"; 
import Ufooter from "../footers/ufooter";

const Ulayout = () => {
  const { pathname } = useLocation();
  return (
    <>
      <Uheader />
      <div className="container">
        
      {pathname === "/" ? <Dashboard /> : <Outlet />}
      </div>
      <Ufooter />
    </>
  );
};

export default Ulayout;
