import React from 'react';
import Fieldset from '../form/fieldset';

const Infoboutique = () => {

function handleSubmit(e) {
  e.preventDefault();
  alert("boutique modifée");
}

    return (
      <form   className="form-setting" onSubmit={handleSubmit}>
        <Fieldset legend="Les informations de ma boutique" color="">
          <div className="form-group">
            <i className="fa-solid fa-store"></i>
            <input type="text" value="Golden-store" />
          </div>
          <div className="form-group">
            <textarea name="" placeholder="Description"></textarea>
          </div>
          <div className="form-group">
            <button className="btn">Mettre à jour</button>
          </div>
        </Fieldset>
      </form>
    );
}

export default Infoboutique;
