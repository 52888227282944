import Axios from "./caller.service";


let all = (id) => {
 return Axios.get("produits/" + id);
};
let one = (id) => {
  return Axios.get("produits/" + id);
};

let create = (data) => {
  return Axios.post("produits/create", data);
};

let update = (data) => {
  return Axios.put("produits/update", data);
};

export const ProduitService ={
    all,create,one,update
}