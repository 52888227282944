import React from "react";
import TitreHomepage from "../titres/TitreHomepage";

const NosChiffres = ({commerce,user}) => {
  return (
    <div className="chiffres">
      <TitreHomepage titre='nos chiffres'/> 

      <div className="boxes">
        <div className="box">
          <p className="value">{user}</p>
          <p className="description">Utilisateurs</p>
        </div>
        <div className="box">
          <p className="value">{commerce}</p>
          <p className="description">Commerces</p>
        </div>
        <div className="box">
          <p className="value">2</p>
          <p className="description">villes</p>
        </div>
      </div>
    </div>
  );
};

export default NosChiffres;
