import React from 'react';
import { Link } from 'react-router-dom';

const Cardvendeur = ({v}) => {
    return (
      <Link className="seller linkable" to={"/u/vendeur/" + v.id}>
        <div className="seller-data">
          <img
            src=" https://images.pexels.com/photos/428333/pexels-photo-428333.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            alt=""
          />
          <h3 className="seller-name">
            <span>
              <i className="fa-solid fa-user"></i> {v.nom} {""}
              {v.prenom}
            </span>
            <span>
              <i className="fa-solid fa-location-dot"></i> Libreville 1
            </span>
            <span className='CodeAgent'>
              <i className="fa-solid fa-location-dot"></i> { v.code}
            </span>
          </h3>
        </div>
        <i className="fa-solid fa-bookmark" title="Become a client"></i>
      </Link>
    );
}

export default Cardvendeur;
