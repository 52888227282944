import React, { useState } from "react";
import "./login.css";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../components/loader/loader";
import { AuthService } from "../../services/auth.service";

const Login = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [login, setLogin] = useState({
    email: "theslayerbeatz@gmail.com",
    password: "Louembet0_",
  });

  function handleLogin(e) {
    setIsLoading(!isLoading);
    e.preventDefault(); 

  async function getDatas() { 
    await AuthService.login(login).then((res) => {
AuthService.saveToken(res.data.access_token)
      navigate('/u') 
    }).catch( err => console.log('err')
      
    ) 
  }

  getDatas(); 

  }

  function handleChange(e){
    let val = e.target.value;
    let nom = e.target.name;
    setLogin({
      ...login,
      [nom]:val
    })
  } 

  return (
    <div id="block">
      <div id="data">
        <div id="message">
          <h1>Bonjour !!!</h1>
          <p>
            Entrez vos informations personnelles et commencez votre voyage avec
            nous
          </p>
          <Link to="/signup" className="btn">
            S'incrire
          </Link>
        </div>
        <div id="login">
          <div id="head">
            <h3>Se connecter</h3>
          </div>
          <form onSubmit={handleLogin}>
            <div className="form-group">
              <i className="fa-solid fa-envelope"></i>
              <input
              name="email"
                type="email"
                placeholder="Email"
                className="auth-input"
                value={login.email}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <i className="fa-solid fa-lock"></i>
              <input
              name="password"
                type="password"
                placeholder="Password"
                className="auth-input"
                value={login.password}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <button type="submit" className="btn">
                {isLoading ? <Loader /> : "se connecter"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
