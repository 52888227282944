import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./vendeur.css";
import Produit from "../../../components/vendeurs/produit";
import { ProduitService } from "../../../services/Produit.service";
import Cart from "../../../components/cart/cart";
import Loader from "../../../components/loader/loader";
import Empty from "../../../components/empty/empty";
import { vendeurService } from "../../../services/Vendeur.Service"; 

const Vendeur = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [vendeur, setVendeur] = useState([]);
  const [search, setSearch] = useState("");
  const [produits, setProduits] = useState([]);
  const [cart, setCart] = useState([]);
  const [isLoading, setIsLoading] = useState();

  /// HOOK ///
  useEffect(() => {
    async function getDatas() {
      const data = await vendeurService.one(id).then((response) => { 
        if (response.status === "404 Not Found") {
          navigate("/notfound");
        } else {
         return response.data;
        }
      });
      setVendeur(data.data);
    }

    getDatas();
  }, [id,navigate]);

  useEffect(() => {
    async function getDatas() {
      setIsLoading(true);
      const data = await ProduitService.all(id).then((response) => {
        if (response.data.message === "Pas de données") {
          return response.data;
        } else {
          return response.data;
        }
      });
      setProduits(data.data);
      setData(data.data);
      setIsLoading(false);
    }

    getDatas();
  }, [id]);



  ///Methodes
  const handleAddCart = (e) => {
    const index = e.target.dataset.index;
    const element = produits[index];
    let CartCopy = [...cart];

    const id = element.id;
    const designation = element.designation;
    const prix = element.prix;
    const qte = 2;

    CartCopy.push({ id, designation, prix, qte });
    setCart(CartCopy);
    setSearch("");
    setProduits(data);
  };
  function handleRemoveCart(id) {
    let CartCopy = [...cart];
    const CartUpdated = CartCopy.filter((c) => c.id !== id);
    setCart(CartUpdated);
  }
  function handleSearch(e) {
    setSearch(e.target.value);
    let ProduitsCopy = [...data];

    const ProduitsUpdated = ProduitsCopy.filter((p) => {
      var nom = p.designation;
      nom = nom.toLowerCase();
      const val = e.target.value.toLowerCase();

      if (nom.includes(val)) {
        return p;
      }
      return null;
    });
    setProduits(ProduitsUpdated);
  }
  function Rows() {
    const rows = [];
    if (!produits.message) {
      produits.forEach((produit, index) => {
        rows.push(ProduitRow(produit, index));
      });
    }
    return isLoading ? <Loader /> : rows.length > 0 ? rows : <Empty />;
  }

  function ProduitRow(produit, index) {
    return (
      <Produit
        p={produit}
        index={index}
        addCart={handleAddCart}
        key={produit.id}
      />
    );
  }

  const rows = Rows();

  return (
    <div className="stores">
      <div id="store">
        <div id="store-header">
          <div id="seller">
            <h2>
              les produits de 
              {vendeur.nom} {vendeur.prenom}
              <br />
              {vendeur.code}
            </h2>
          </div>
          <div id="search-bar">
            <input
              type="search"
              placeholder="search..."
              value={search}
              onChange={handleSearch}
            />
          </div>
        </div>
        <div id="products">{rows}</div>
      </div>
      <Cart cart={cart} remove={handleRemoveCart} />
    </div>
  );
};

export default Vendeur;
