import React from "react";
import { Link } from "react-router-dom";
import TitreHomepage from "../titres/TitreHomepage";
import { tarif } from "../../helper/fonctions";

const Pricing = () => {
  return (
    <div className="pricing">
      <TitreHomepage titre="tarifs" />

      <div class="all_prices">
        <div class="box_price">
          <div class="head_price">
            <h5>Gratuit</h5>
            <h3>{tarif(0)}</h3>
          </div>

          <div class="body_price">
            <ul>
              <li>faire des bons</li>
              <li>acces aux statistiques</li>
              <li>jusqu'a 3 boutiques</li>
              <li>faire des bons</li>
            </ul>
          </div>

          <Link class="btn" to="/signup">
            Commander
          </Link>
        </div>
        <div class="box_price">
          <div class="head_price">
            <h5>Bronze</h5>
            <h3>{tarif(2000)}</h3>
          </div>

          <div class="body_price">
            <ul>
              <li>creation d'une boutique</li>
              <li>acces aux statistiques</li>
              <li>jusqu'a 3 boutiques</li>
              <li>faire des bons</li>
            </ul>
          </div>

          <Link class="btn" to="/signup">
            devenir vendeur
          </Link>
        </div>
        <div class="box_price">
          <div class="head_price">
            <h5>Silver</h5>
            <h3>{tarif(9000)}</h3>
          </div>

          <div class="body_price">
            <ul>
              <li>creation d'une boutique</li>
              <li>acces aux statistiques</li>
              <li>jusqu'a 3 boutiques</li>
              <li>faire des bons</li>
            </ul>
          </div>

          <Link class="btn" to="/signup">
            devenir vendeur
          </Link>
        </div>
        <div class="box_price">
          <div class="head_price">
            <h5>gold</h5>
            <h3>{tarif(20000)}</h3>
          </div>

          <div class="body_price">
            <ul>
              <li>creation d'une boutique</li>
              <li>acces aux statistiques</li>
              <li>jusqu'a 3 boutiques</li>
              <li>faire des bons</li>
            </ul>
          </div>

          <Link class="btn" to="/signup">
            devenir vendeur
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
