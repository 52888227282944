import Axios from "./caller.service";
import jwt_decode from "jwt-decode";

let login = (data) => {
  return Axios.post("auth/login/", data);
};

let saveToken = (token) => {
  localStorage.setItem("token", token);
};

let logout = () => {
  localStorage.removeItem("token");
};

let isLogged = () => {
  let token = localStorage.getItem("token");
  return !!token;
};
let getToken = () => {
  let token = localStorage.getItem("token");
  return token;
};
let decode = () => {
  var token = getToken();
  var decoded = jwt_decode(token);

  return decoded;
};

let getNom = () => {
  var user = decode();
  

  return user.nom;
};



export const AuthService = {
  login,
  saveToken,
  logout,
  isLogged,
  decode,
  getNom,
};
