import React from 'react';

import { Route, Routes } from "react-router-dom";  
import Dashboard from './particulier/dashboard';
import Ulayout from '../../components/layouts/ulayout';
import Profil from './particulier/profil';
import Mesvendeurs from './particulier/mesvendeurs';
import Vendeur from './particulier/vendeur';
import Mestickets from './particulier/mestickets';
import Ticket from './particulier/ticket';
import Parametres from './particulier/parametres';
import Demandes from './particulier/demandes';
import Caisse from './particulier/caisse';
import Error404 from '../public/Error404';
import { AuthService } from '../../services/auth.service';
const UserRoute = () => {


  
console.log(AuthService.decode());
    return (
      <Routes>
        <Route element={<Ulayout />}>
          <Route index element={<Dashboard />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="profil" element={<Profil />} />
          <Route path="caisse" element={<Caisse />} />
          <Route path="demandes" element={<Demandes />} />
          <Route path="mesvendeurs" element={<Mesvendeurs />} />
          <Route path="parametres" element={<Parametres />} />  
          <Route path="vendeur/:id" element={<Vendeur />} />
          <Route path="mestickets/*" element={<Mestickets />}>
            <Route path=" /:etat" element={<Mestickets />} />
          </Route>
          <Route path="ticket/:id" element={<Ticket />} />
          <Route path="*" element={<Error404 />} />
        </Route>
      </Routes>
    );
}

export default UserRoute;
