import React from 'react';

const Error404 = () => {
    return (
        <div>
            Page non trouvée
        </div>
    );
}

export default Error404;
