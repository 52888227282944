import React from 'react';
import Fieldset from '../form/fieldset';

const Infoperso = () => {

function handleSubmit(e) {
  e.preventDefault();
  alert("Info personelles modifées");
}

    return (
      <form className="form-setting" onSubmit={handleSubmit}>
        <Fieldset legend="Mes informations personnelles" color="">
          <div className="form-group">
            <i className="fa-solid fa-user"></i>
            <input type="text" value="Golden-gab" />
          </div>
          <div className="form-group">
            <i className="fa-solid fa-phone"></i>
            <input type="tel" placeholder="Entrez votre numéro de téléphone" />
          </div>

          <div className="form-group">
            <button className="btn">Mettre à jour</button>
          </div>
        </Fieldset>
      </form>
    );
}

export default Infoperso;
