import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Dashboard from './particulier/dashboard';
import Error404 from '../public/Error404';
import Vllayout from '../../components/layouts/Vllayout';
import Parametres from './vendeur/parametres/parametres';

const VendeurRoute = () => {
    return (
      <Routes>
        <Route element={<Vllayout />}>
          <Route index element={<Dashboard />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="parametres" element={<Parametres />} />
          <Route path="*" element={<Error404 />} />
        </Route>
      </Routes>
    );
}

export default VendeurRoute;
