import React from 'react'; 
import LineTickets from '../../../components/tickets/lineTickets';
import TitreTable from '../../../components/titres/TitreTable';
const Mestickets = () => {

 
  return (
    <div id="mytickets">
      <div id="tickets-container">
        <TitreTable titre="Mes tickets" />
        <table id="myTable" className="display">
          <thead>
            <tr>
              <th>#</th>
              <th>Vendeur</th>
              <th>date</th>
              <th>montant</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <LineTickets />
            <LineTickets />
            <LineTickets />
            <LineTickets />
            <LineTickets />
            <LineTickets />
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Mestickets;
