import React from 'react';
import { Outlet, useLocation } from 'react-router-dom'; 
import Pheader from '../headers/pheader';
import Pfooter from '../footers/pfooter';
import Home from '../../pages/public/home/home';

const Playout = () => {
  const { pathname } = useLocation();

  return (
    <>
      <Pheader />
      <div className="pcontainer">
        
      {pathname === "/" ? <Home /> : <Outlet />}
      </div>
      <Pfooter />
    </>
  );
}
export default Playout;
