import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Dashboard from "../../pages/users/particulier/dashboard";
import Ufooter from "../footers/ufooter";
import Vheader from "../headers/vheader";

const Vllayout = () => {
  const { pathname } = useLocation();
  return (
    <>
      <Vheader />
      <div className="container">
        {pathname === "/" ? <Dashboard /> : <Outlet />}
      </div>
      <Ufooter />
    </>
  );
};

export default Vllayout;
