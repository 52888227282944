import React, { useRef } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo/logo.png";
import Logout from "../boutons/logout/logout";



const Vheader = () => {
  const MenuUser = useRef();
  function handleClicMenu(e) {
    MenuUser.current.classList.toggle("active");
    window.scrollTo(0, 0);
  }

  function handleClicMenuBtn(e) {
    MenuUser.current.classList.toggle("active"); 
  }


  return (
    <>
      <header className="pheader">
        <Link to={"/"} className="logo">
          <img src={logo} alt="" />
        </Link>
      </header>
      <aside className="aside " ref={MenuUser}>
        <ul id="aside-body">
          <li onClick={handleClicMenu}>
            <Link to="/v/dashboard">
              <i className="fa-solid fa-dashboard"> </i>
              <span>Tableau de bord</span>
            </Link>
          </li>
          {/* <li onClick={handleClicMenu} >
          <Link to="../mystore/createStore.php">
            <i className="fa-solid fa-store"> </i>
            <span>My store</span>
          </Link>
        </li> */}
          <li onClick={handleClicMenu}>
            <Link to="mesclients">
              <i className="fa-solid fa-users"></i>
              <span>Mes clients</span>
            </Link>
          </li>
          <li onClick={handleClicMenu}>
            <Link to="caisse">
              <i className="fa-solid fa-cash-register"></i>
              <span>caisse</span>
            </Link>
          </li>
          <li onClick={handleClicMenu}>
            <Link to="demandes">
              <i className="fa-solid fa-file"></i>
              <span>Mes requetes</span>
            </Link>
          </li>
          <li onClick={handleClicMenu}>
            <Link to="mestickets">
              <i className="fa-solid fa-ticket"></i>
              <span>Mes tickets</span>
            </Link>
          </li>
          <li onClick={handleClicMenu}>
            <Link to="profil">
              <i className="fa-solid fa-user"></i>
              <span>Amilou</span>
            </Link>
          </li>
          <li onClick={handleClicMenu}>
            <Link to="parametres">
              <i className="fa-solid fa-gear"></i>
              <span>parametres</span>
            </Link>
          </li> 
        </ul>
        <Logout />
      </aside>

      <div id="btn-aside" onClick={handleClicMenuBtn}>
        <i className="fa-solid fa-bars-staggered" id="icon_aside"></i>
      </div>
    </>
  );
};

export default Vheader;
