import React, { useEffect, useState } from "react";
import './mesvendeurs.css' 
import Cardvendeur from "../../../components/vendeurs/cardvendeur";
import { vendeurService } from "../../../services/Vendeur.Service";
import Loader from "../../../components/loader/loader";

const Mesvendeurs = () => {



  const [data, setData] = useState([]);
  const [vendeurs, setVendeurs] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [search, setSearch] = useState("");




/// HOOK ///
  useEffect(() => {
    async function getDatas() {
      setIsLoading(true);
      const data = await vendeurService
        .all()
        .then((response) => {
          if (response.data.message === "Pas de données") {
            return response.data;
          } else {
            return response.data;
          }
        });
      setVendeurs(data.data);
      setData(data.data);
      setIsLoading(false)
    }

    getDatas(); 
  }, []);


  function handleSearch(e) {
    setSearch(e.target.value);
    let VendeursCopy = [...data];

    const vendeursUpdated = VendeursCopy.filter((p) => {
      var nom = p.nom;
      var prenom = p.prenom;
      var code = p.code;
      nom = nom.toLowerCase();
      prenom = prenom.toLowerCase();
      code = code.toLowerCase();
      const val = e.target.value.toLowerCase();

      if (nom.includes(val)||code.includes(val)||prenom.includes(val) ) {
        return p;
      }
      return null;
    }); 
    setVendeurs(vendeursUpdated);
  }

  function Rows() {
    const rows = [];
    if (!vendeurs.message) {
      vendeurs.forEach((vendeur ,index) => {
        rows.push(VendeurRow(vendeur,index));
      });
    }
    return isLoading ? <Loader /> : rows;
  }
  
  function VendeurRow(vendeurs ,index) {
    return <Cardvendeur v={vendeurs} key={index} />; 
  }

  const rows = Rows();



  return (
    <div id="mysellers">
      <div id="store-header">
        <div>
          <h2>Trouver un vendeur</h2>
        </div>
        <div id="search-bar">
          <i className="fa-solid fa-bookmark" title="My sellers"></i>
          <input type="search" placeholder="search..." value={search} onChange={handleSearch} />
        </div>
      </div>
      <div id="sellers"> 
     { rows}
      </div>
    </div>
  );
};

export default Mesvendeurs;
