import React from 'react';
import Footer from './footer';

const Ufooter = () => {
    return (
      <footer className="user">
        <Footer />
      </footer>
    );
}

export default Ufooter;
