import React from 'react';
import { Link } from 'react-router-dom';
import {  tarif } from "../../helper/fonctions";

const LineCaisse = () => {
    return (
      <tr>
        <td>1</td>
        <td>January</td>
        <td>{tarif(150000)}</td>
        <td>
          <Link to="/u/ticket/2">
            <i className="fa-solid fa-eye"></i>
          </Link>
        </td>
      </tr>
    );
}

export default LineCaisse;
