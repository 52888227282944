import React from 'react';
import {  tarif } from '../../helper/fonctions';

const Produit = ({ p, addCart ,index}) => {
  return (
    <div className="product">
      <div className="info-header">
        <h3>
          <span className="title"> {p.designation}</span> 
          <span> {tarif(p.prix)}  </span>
        </h3>
        <button onClick={addCart}>
          <i className="fa-solid fa-cart-shopping" data-index={index}></i>
        </button>
      </div>
    </div>
  );
};

export default Produit;
