import React from 'react';
import { Link } from "react-router-dom";

const Avatar = ({src , alt='', linkEdit=' '}) => {
    return (
        <div className="avatar-container">
            <img src={src} alt={alt} className="avatar" />
            <Link to={linkEdit} className="avatar-link">
                <i className="fa-solid fa-image"></i>
            </Link>
        </div>
    );
}

export default Avatar;
