import React from 'react';

const ArticleRecent = () => {
    return (
      <>
        <div className="bestof">
          <h3> articles Recents</h3>
          <div className="boxes">
            {box()}
            {box()}

            {box()} 
          </div>
        </div>
      </>
    );
}

function box() {
  return (
    <div className="box">
      <span>#1</span>
      <p className="title">
        Boite de lait <br />
        2fois
      </p>
    </div>
  );
}
export default ArticleRecent;
